import { ProductProps } from "../../Types/ProductTypes";
import { Link } from "react-router-dom";
const ProductListItem = ({product}: ProductProps) => {
    return (
        <div className="card bg-light">
            <div className="card-body"> 
                <Link to={"/products/"+product.id} className="ext-decoration-none">
                    <h6 className="card-title text-decoration-none text-dark">{product.name}</h6>
                </Link>
            </div>
        </div>
    )
};

export default ProductListItem;