import { useState, useEffect } from "react";
import { InstructionForm, initialProductInstruction } from "../../Types/ProductTypes";
import Quill from 'quill';
import ImageResize from 'quill-image-resize';
Quill.register('modules/imageResize', ImageResize);

const ProductInstructionForm = ({instruction, instructionSubmit, close} : InstructionForm) => {
    const [newInstructionName, setNewInstructionName] = useState(initialProductInstruction.name);
    const [isValid, setIsValid] = useState(true);
    const requiredFieldMessage = "A mező kitöltése kötelező!";
    const initialEditorValue = "<p><br></p>";
    let quill;
    const handleNameChange =  (event: any) => {
        setNewInstructionName(event.target.value);
    }
    useEffect(() => {
        setNewInstructionName(instruction.name);
        const descriptionContainer = document.getElementById('description');
        if(descriptionContainer) {            
            document.querySelector(".ql-toolbar")?.remove();            
            descriptionContainer.innerHTML = instruction.description;
            initEditor();
        }
    }, [instruction]);

    const initEditor = (): void => {        
        quill = new Quill('#description', {
            modules: {
                toolbar: [['bold', 'italic'], ['link', 'image']],
                imageResize: {}
            },
            theme: "snow"
        });
    };


    const submit = () => {
        let instructionBody = document.querySelector('.ql-editor');
        console.log(instructionBody?.innerHTML)
        if(instructionBody && instructionBody.innerHTML && instructionBody.innerHTML !== initialEditorValue && newInstructionName) {
            instructionSubmit({
                name: newInstructionName,
                description: instructionBody?.innerHTML,
                index: instruction.index
            });
            instructionBody.innerHTML = "";
            setNewInstructionName("");
        }
        else {
            setIsValid(false);
        }
        
    };

    const closeForm  = () => {
        close();
    };


    return (
        <div>
            <div className="my-3">
                <label className="form-label">Cím</label>
                <input type="text" className={`form-control ${!isValid && !newInstructionName ? 'is-invalid' : ''}`} value={newInstructionName} onChange={handleNameChange}/>
                {!isValid && <div className="invalid-feedback">{requiredFieldMessage}</div>}
            </div>
            <div className="mb-3">
                <label className="form-label">Tartalom</label>
                <div id="description"></div>
                {!isValid && <div className={`text-danger ${!isValid && !document.querySelector('.ql-editor')?.innerHTML ? "d-block" : ""}` }>{requiredFieldMessage}</div>}
            </div>           
            <div>
                <button className="btn btn-sm btn-primary me-2" type="button" onClick={() => submit()}>{instruction.index ? "Lépés módosítása" : "Lépés hozzáadása"}</button>
                <button className="btn btn-sm btn-secondary" type="button" onClick={() => closeForm()}>Mégsem</button>
            </div>            
        </div>
    );
}

export default ProductInstructionForm;