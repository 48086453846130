import { Link } from "react-router-dom";
import { WasteCategory } from "../../Types/PieceTypes";
const WasteCategoryListItem = ({name, id}: WasteCategory) => {
    return (
        <div className="card bg-light">
            <div className="card-body"> 
                <Link to={"/waste-categories/"+id} className="ext-decoration-none">
                    <h6 className="card-title text-decoration-none text-dark">{name}</h6>
                </Link>
            </div>
        </div>
    )
};

export default WasteCategoryListItem;