
import { useGetProductQuery, useUpdateProductMutation } from "../../Store/SwService";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import ProductForm from "./ProductForm";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
const ProductUpdate = () => {  
    const { id } = useParams();
    let { data } = useGetProductQuery(Number(id));
    const [updateProduct, { isLoading, isSuccess, isError }] = useUpdateProductMutation();
    const onSubmit = async (formData: any) => {
        updateProduct({
            id: data?.id,
            ...formData
        });         
    };
    const navigate = useNavigate();
    useEffect(() => {

        if(isSuccess && !isError) {
            navigate("/products");
        }
        
    }, [isSuccess, isError]);
    
    
    
    return (
        <div>
            <div className="d-flex justify-content-between mb-3">
                <h2 className="fs-4">Termék módosítása: {data?.name}</h2>
                <Link className="btn btn-secondary" to="/products">
                    <i className="bi bi-arrow-left-square me-2"></i>
                    <span>Termékek</span>
                </Link>
            </div>  
            {data && <ProductForm product={data} submit={onSubmit} ></ProductForm>}
        </div>
    );
};
export default ProductUpdate;