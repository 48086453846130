import { useGetProductQuery, useDeleteProductMutation } from "../../Store/SwService";
import {useParams} from 'react-router-dom';
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import SweetAlert2 from 'react-sweetalert2';
import { useState, useEffect } from "react";
import { initialAlertModal } from "../../Types/CommonTypes";
import { useNavigate } from 'react-router-dom';
import { ProductInstruction } from "../../Types/ProductTypes";

const ProductShow = () => {
    const { id } = useParams();
    const { data } = useGetProductQuery(Number(id));
    const [instructionsToSort, setInstructionsToSort] = useState(Array<ProductInstruction>);   
    const [deleteProduct, {isSuccess, isError}]   = useDeleteProductMutation();
    const [swalProps, setSwalProps] = useState(initialAlertModal);
    const navigate = useNavigate();
    const handleAlert = (): void => {
        setSwalProps({
            show: true,
            title: 'Törlés',
            text: 'Biztosan törölni szeretné a terméket?',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            confirmButtonText: "Törlés",
            cancelButtonText: "Mégsem"
        });
    };
    const handleDelete = (): void => {
        if(data) {
            deleteProduct(data);
        }
    };
    useEffect(() => {
        if(isSuccess && !isError) {
            navigate("/products");
        }

    }, [isError, isSuccess]);
    useEffect(() => {
        if(data) {
            setInstructionsToSort([...data.instruction].sort((a,b) => a.index - b.index))
        }

    }, [data]);
    return (
        <div>
            <div className="d-flex justify-content-between mb-3">
                <h2 className="fs-4">{data && data.name}</h2>
                <Link className="btn btn-secondary" to="/products">
                    <i className="bi bi-arrow-left-square me-2"></i>
                    <span>Termékek</span>
                </Link>
            </div>  
            <div className="row">
                <div className="col-12 col-sm-6">
                    <dl>
                        <dt>Vonalkód</dt>
                        <dd>{data && data.barcode}</dd>
                        <dt>Belső azonosító</dt>
                        <dd>{data && data.internalId}</dd>
                        <dt>Ciklusidő</dt>
                        <dd>{data && data.cycleTime}</dd>
                    </dl>
                    <div>
                        <Link className="btn btn-warning me-1 text-white" to={`/products/${data?.id}/update`}>Módosítás</Link>
                        <button className="btn btn-danger me-1" onClick={handleAlert}>Törlés</button>
                    </div>
                </div>
                <div className="col-12 col-sm-6">
                    <h5>Instrukciók</h5>
                    <hr></hr>
                    {instructionsToSort.map((instruction, index) => (
                        <div key={index}>
                            <h6>{instruction.index}. Lépés</h6>
                            <span>{instruction.name}</span>
                            <div>
                                {parse(instruction.description)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>   
            <SweetAlert2   
                {...swalProps} 
                onConfirm={result => {
                    if(result) {
                        handleDelete();
                    }
                }}
                didClose={() => {
                    setSwalProps({show: false, title: "", text: ""})
                }}
            />
        </div>
    );
};
export default ProductShow;