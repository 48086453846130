import { useAppSelector } from "../../Hooks/TypedHooks";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
const Sidebar = () => {
    const user = useAppSelector((state) => state.user.value);   
    return (
        <div className="d-flex flex-column flex-shrink-0 p-3 bg-light sidebar expanded">
            <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                <h1 className="fs-4">SW Manager</h1>
            </Link>
            <hr/>
            <ul className="nav nav-pills flex-column mb-auto">
                <li className="nav-item">
                    <Link to="/" className="nav-link link-dark">
                        <i className="bi bi-speedometer2 me-2"></i>
                        Irányítópult
                    </Link>
                </li>
                <li>
                    <Link to="/workstations" className="nav-link link-dark">
                        <i className="bi bi-grid-fill me-2"></i>
                        Munkaállomások
                    </Link>
                </li> 
                <li>
                    <Link to="/products" className="nav-link link-dark">
                        <i className="bi bi-box2 me-2"></i>
                        Termékek
                    </Link>
                </li>   
                <li>
                    <Link to="/waste-categories" className="nav-link link-dark">
                        <i className="bi bi-trash3 me-2"></i>
                        Selejt kategóriák
                    </Link>
                </li>     
                <li>
                    <Link to="/production-info" className="nav-link link-dark">
                        <i className="bi bi-clipboard-data me-2"></i>
                        Gyártás
                    </Link>
                </li>      
            </ul>
            <hr/>
            <Dropdown className="d-inline mx-2">
                <Dropdown.Toggle role="button" as={"a"} className="text-decoration-none text-dark">
                    <i className="bi bi-person-circle me-2 fs-2 "></i>
                    <strong className=" text-decoration-none">{user.firstName} {user.lastName}</strong>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item href="#">Profil</Dropdown.Item>
                    <Dropdown.Item href="#">Kijelentkezés</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>           
        </div>
    );
}
export default Sidebar;