import { initialProduct, initialProductInstruction, ProductProps, ProductInstruction } from "../../Types/ProductTypes";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import  ProductInstructionForm  from "./ProductInstructionForm";
import ProductInstructionList from "./ProductInstructionList";

const ProductForm = ({product, submit}: ProductProps) => {
    const [newProduct, setNewProduct] = useState(initialProduct);
    const [instructionToModify, setInstructionToModify] = useState(initialProductInstruction);
    const [instructions, setInstructions] = useState(Array<ProductInstruction>);
    const [showInstructionForm, setShowInstructionForm] = useState(true);
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            name: product.name,
            internalId: product.internalId,
            barcode: product.barcode
        }
    });
    const requiredFieldMessage = "A mező kitöltése kötelező!";
    
    useEffect(() => {
        if(typeof product.id !== "undefined") {
            setNewProduct(product);
            setInstructions(product.instruction);
            if(product.instruction.length) {
                setShowInstructionForm(false);
            }
        }
    }, []);

    const onSubmit = (data: any) => {  
        data.instruction = instructions;
        setNewProduct(data);      
        submit(data);
    };
    const onInstructionSubmit = (instruction: ProductInstruction) => {
        let newInstructions = [...instructions];
        if(instruction.index) {
            const inst = newInstructions.find(i => i.index === instruction.index)
            if(inst) {
                const index = newInstructions.indexOf(inst);
                newInstructions[index] = instruction;
            }
        }
        else {
            instruction.index = newInstructions.length ? newInstructions.length + 1 : 1;
            newInstructions.push(instruction);  
        }   
        setInstructions(newInstructions);     
        setShowInstructionForm(false);
    }
    const onIstructionClose = () => {
        setShowInstructionForm(false);
    };
    const deleteInstruction = (instruction: ProductInstruction): void => {
        setInstructions(instructions.filter(inst => inst !== instruction));
    }
    const modifyInstruction = (instruction: ProductInstruction): void => {
        setInstructionToModify(instruction);
        setShowInstructionForm(true);
    }
    const refreshInstructions = (instructions: Array<ProductInstruction>): void => {
        setInstructions([...instructions]);
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
                <label className="form-label">Terméknév</label>
                <input type="text" className={`form-control ${errors.name ? 'is-invalid' : ''}`} {...register("name", { required: true })}/>
                {errors.name && <div className="invalid-feedback">{requiredFieldMessage}</div>}
            </div>
            <div className="mb-3">
                <label className="form-label">Belső azonosító</label>
                <input type="text" className={`form-control ${errors.internalId ? 'is-invalid' : ''}`} {...register("internalId", { required: true })}/>
                {errors.internalId && <div className="invalid-feedback">{requiredFieldMessage}</div>}
            </div>
            <div className="mb-3">
                <label className="form-label">Vonalkód</label>
                <input type="text" className={`form-control ${errors.barcode ? 'is-invalid' : ''}`} {...register("barcode", { required: true })}/>
                {errors.barcode && <div className="invalid-feedback">{requiredFieldMessage}</div>}
            </div>  
            <div>
                <div className="d-flex justify-content-between mb-3">
                    <h5>Instrukciók</h5>
                    {!showInstructionForm && <button className="btn btn-sm btn-primary" type="button" onClick={() => {setInstructionToModify(initialProductInstruction); setShowInstructionForm(true)}}>
                        <i className="bi bi-plus-square me-2"></i>
                        <span>
                            Új instrukció
                        </span>
                    </button>}
                </div>
                <ProductInstructionList instructions={instructions} onDelete={deleteInstruction} onModify={modifyInstruction} onSort={refreshInstructions}></ProductInstructionList>
            </div> 
            <div>
                {showInstructionForm && <ProductInstructionForm instruction={instructionToModify} instructionSubmit={onInstructionSubmit} close={onIstructionClose}></ProductInstructionForm>}
            </div>

            <div className="mt-3">
                {(instructions.length !== 0 && !showInstructionForm) && <button className="btn btn-primary" type="submit">Mentés</button>}
            </div>
            
        </form>
    )
};
export default ProductForm;