import Keycloak from "keycloak-js";
import jwtDecode from 'jwt-decode';
import { User } from "../Types/UserTypes";
import config from "../../kc-config.json";
class AuthHelper {
    public static init (): Keycloak {
        return new Keycloak({
            url: config.url,
            realm: config.realm,
            clientId: config.clientId                    
        });
    }

    public static decodeJwt(token: string | undefined): any {
        if(token) {
            return jwtDecode(token);
        }
        return undefined;
    }

    public static saveToken(token: string | undefined) {
        if(token) {
            localStorage.setItem('token', token);
        }       
    }

    public static getTokenFromStorage(): string | null {
        return localStorage.getItem('token');
    }

    public static parseToken(token: string | undefined): User {
        const tokenData = AuthHelper.decodeJwt(token);
        const user: User = {
            id: "",
            email: tokenData.email,
            firstName: tokenData.given_name,
            lastName: tokenData.family_name,
            groups: tokenData.groups,
            sub: tokenData.sub,
            token: token
        };
        return user;
    }
}

export default AuthHelper;