import { Link } from "react-router-dom";
import { useAppSelector } from "../../Hooks/TypedHooks";
const DashboardMain = () => {
    const user = useAppSelector((state) => state.user.value);
    return (
        <div>
            <Link to="/products">Termékek</Link>
            <div>
                user: {user.lastName}
            </div>
        </div>
    );
};

export default DashboardMain;