import { instructionList, ProductInstruction } from "../../Types/ProductTypes";
import ListGroup from 'react-bootstrap/ListGroup';
import { useEffect, useState } from "react";

const ProductInstructionList = ({instructions, onDelete, onModify, onSort}: instructionList) => {  

    const [instructionsToSort, setInstructionsToSort] = useState(Array<ProductInstruction>);   
    const existNext = (index: number, direction: string): boolean => {
        const nextInstructions = direction === "up" ? instructions.filter(inst => inst.index < index) : instructions.filter(inst => inst.index > index);
        return nextInstructions.length ? true : false;
    };

    const setIndex = (instruction: ProductInstruction, direction: string): void => {
        let newList = [...instructionsToSort];        
        const arrayIndex = newList.indexOf(instruction);  
        const affectedArrayIndex = direction === "up" ?  arrayIndex - 1 : arrayIndex + 1;
        let a = {...newList[arrayIndex]};
        a.index = newList[affectedArrayIndex].index;
        let b = {...newList[affectedArrayIndex]};
        b.index = newList[arrayIndex].index;
        newList[arrayIndex] = a;
        newList[affectedArrayIndex] = b;
        onSort(newList);
    };

    useEffect(() => {        
        setInstructionsToSort([...instructions].sort((a, b) => a.index - b.index));  
    }, [instructions]);

    return (
        <ListGroup>
            {instructionsToSort.map((instruction, key )=> instruction.name &&
                <ListGroup.Item key={key}>
                    <div className="d-flex justify-content-between">
                        <span>{instruction.name}</span>
                        <div>
                            {existNext(instruction.index, "up") && <i className="bi bi-arrow-up-square me-2" onClick={() => setIndex(instruction, "up")}></i>}
                            {existNext(instruction.index, "down") && <i className="bi bi-arrow-down-square me-2" onClick={() => setIndex(instruction, "down")}></i>}


                            <i className="bi bi-tools text-warning me-2" onClick={() => onModify(instruction)}></i>
                            <i className="bi bi-x-square-fill text-danger" onClick={() => onDelete(instruction)}></i>
                        </div>                        
                    </div> 
                </ListGroup.Item>
            )}
        </ListGroup>
    );    
};

export default ProductInstructionList;