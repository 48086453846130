import { useGetWasteCategoryQuery, useDeleteWasteCategoryMutation } from "../../Store/SwService";
import {useParams} from 'react-router-dom';
import { Link } from "react-router-dom";
import SweetAlert2 from 'react-sweetalert2';
import { useState, useEffect } from "react";
import { initialAlertModal } from "../../Types/CommonTypes";
import { useNavigate } from 'react-router-dom';

const WasteCategoryShow = () => {
    const { id } = useParams();
    const { data } = useGetWasteCategoryQuery(Number(id));
    const [deleteCategory, {isSuccess, isError}]   = useDeleteWasteCategoryMutation();
    const [swalProps, setSwalProps] = useState(initialAlertModal);
    const navigate = useNavigate();
    const handleAlert = (): void => {
        setSwalProps({
            show: true,
            title: 'Törlés',
            text: 'Biztosan törölni szeretné a kategóriát?',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            confirmButtonText: "Törlés",
            cancelButtonText: "Mégsem"
        });
    };
    const handleDelete = (): void => {
        if(data) {
            deleteCategory(data);
        }
    };
    useEffect(() => {
        if(isSuccess && !isError) {
            navigate("/waste-categories");
        }

    }, [isError, isSuccess]);
    return (
        <div>
            <div className="d-flex justify-content-between mb-3">
                <h2 className="fs-4">{data && data.name}</h2>
                <Link className="btn btn-secondary" to="/waste-categories">
                    <i className="bi bi-arrow-left-square me-2"></i>
                    <span>Selejt kategóriák</span>
                </Link>
            </div>  
            <div className="row">
                <div className="col-12 col-sm-6">
                    <dl>
                        <dt>Név</dt>
                        <dd>{data && data.name}</dd>
                    </dl>
                    <div>
                        <Link className="btn btn-warning me-1 text-white" to={`/waste-categories/${data?.id}/update`}>Módosítás</Link>
                        <button className="btn btn-danger me-1" onClick={handleAlert}>Törlés</button>
                    </div>
                </div>
           
            </div>   
            <SweetAlert2   
                {...swalProps} 
                onConfirm={result => {
                    if(result) {
                        handleDelete();
                    }
                }}
                didClose={() => {
                    setSwalProps({show: false, title: "", text: ""})
                }}
            />
        </div>
    );
};
export default WasteCategoryShow;