import { Product } from "./ProductTypes";
export type Workstation = {
    id: string,
    name: string,
    actualProduct?: Product,
    createdAt?: Date
};

export const initialWorkstation: Workstation = {
    id: "",
    name: ""
};

export type WorkstationProps = {
    workstation: Workstation
};