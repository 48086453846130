export type PaginatedList<Data> = {
    apiDocs: Array<string>,
    count: Number,
    docs: Array<Data>,
    limit: Number,
    page: Number
};
export type ApiResponse<Data> = {
    data: Data,
    success: Boolean,
    message: string
};
export type alertModal = {
        show: boolean,
        title: string,
        text: string,
        showConfirmButton?: boolean,
        showCancelButton?: boolean,
        confirmButtonColor?: string,
        cancelButtonColor?:  string,
        confirmButtonText?: string,
        cancelButtonText?: string
}

export const initialAlertModal: alertModal = {
        show: false,
        title: "",
        text: ""
};