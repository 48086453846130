import { useGetProductsQuery, useGetPiecesQuery, useGetWasteCategoriesQuery, useGetWorkstationsQuery, useGetWasteQuery } from "../../Store/SwService";
import { useEffect, useState } from "react";
import { Product, initialProduct } from "../../Types/ProductTypes";
import { Workstation, initialWorkstation } from "../../Types/WorkstationTypes";
import { WasteCategory, initialWasteCategory } from "../../Types/PieceTypes";
const ProductionInfo = () => {
    const [chosenProduct, setChosenProduct] = useState<Product>(initialProduct);
    const [chosenWorkstation, setChosenWorkstation] = useState<Workstation>(initialWorkstation);
    const [chosenCategory, setChosenCategory] = useState<WasteCategory>(initialWasteCategory);
    const products  = useGetProductsQuery(1);
    const workstations = useGetWorkstationsQuery(1);
    const wasteCategories = useGetWasteCategoriesQuery(1);
    const {data: pieces, isLoading: piecesIsloading, refetch: refetchPieces} = useGetPiecesQuery({page: 1, limit: 10000, products: chosenProduct.id ? [chosenProduct.id] : [], workstations: chosenWorkstation.id ?[chosenWorkstation.id] : [], filter: ""});
    const {data: waste, isLoading: wasteIsloading, refetch: refetchWaste} = useGetWasteQuery({page: 1, limit: 10000, categoryIds: [chosenCategory.id], filter: ""});
    
    const handleProductChange = (productID: number) => {
        const product = products.data ? products.data.docs.find(p =>  p.id === productID) : initialProduct;
        if(product && product.id) {
            setChosenProduct(product);
        }        
    };

    const handleWorkstationChange = (workstationID: string) => {
        const workstation = workstations.data ? workstations.data.docs.find(p =>  p.id == workstationID) : initialWorkstation;
        if(workstation) {
            setChosenWorkstation(workstation);
        }        
    };

    const handleWasteCategoryChange = (categoryID: number) => {
        const category = wasteCategories.data ? wasteCategories.data.docs.find(p =>  p.id == categoryID) : initialWasteCategory;
        if(category) {
            setChosenCategory(category);
        }        
    };

    useEffect(() => {
        refetchPieces();
    }, [chosenProduct, chosenWorkstation]);

    useEffect(() => {
        refetchWaste();
    }, [chosenCategory]);

    return (
        <div className="row">
            <h2 className="fs-4">Gyártás</h2>
            <div className="col-6">
                <div className="row">
                    <h3 className="fs-6">Gyártott darab</h3>
                    <div className="col">
                        <label className="form-label">Terméknév</label>
                        <select className="form-control" value={chosenProduct.id} onChange={(e) => handleProductChange(Number(e.target.value))}>
                            <option value={0}>-</option>
                            {products.data && products.data.docs.map(product => <option key={product.id} value={product.id}>{product.name}</option>)}
                        </select>
                    </div>
                    <div className="col">
                        <label className="form-label">Munkaállomás</label>
                        <select className="form-control" value={chosenWorkstation.id} onChange={(e) => handleWorkstationChange(e.target.value)}>
                            <option value={0}>-</option>
                            {workstations.data && workstations.data.docs.map(workstation => <option key={workstation.id} value={workstation.id}>{workstation.name}</option>)}
                        </select>
                    </div> 
                        
                </div>
                <div className="row px-2">                
                    {pieces && pieces?.docs.map((piece, index) => 
                        <div className={`card ${piece.waste ? 'bg-danger' : 'bg-light'}`} key={index}>
                            <div className="card-body"> 
                                <h6 className="card-title text-decoration-none text-dark">{piece.product.name} - {piece.id}</h6> 
                                <p>
                                    <strong>Munkaállomás: </strong><span>{piece.workstation.name}</span>    
                                </p>                                   
                                <p>
                                    <strong>Gyártási idő: </strong><span>{piece.start}</span>    
                                </p>          
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <h3 className="fs-6">Selejt</h3>
                    <div className="col">
                        <label className="form-label">Selejt kategória</label>
                        <select className="form-control" value={chosenCategory.id} onChange={(e) => handleWasteCategoryChange(Number(e.target.value))}>
                            <option value={0}>-</option>
                            {wasteCategories.data && wasteCategories.data.docs.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
                        </select>
                    </div> 
                </div>
                <div className="row px-2">                
                    {waste && waste?.docs.map((waste, index) => 
                        <div className="card bg-light" key={index}>
                            <div className="card-body"> 
                                <h6 className="card-title text-decoration-none text-dark">{waste.piece.product.name} - {String(waste.id)} - Darab id: {String(waste.piece.id)}</h6> 
                                <p>
                                    <strong>Kategória: </strong><span>{waste.wasteCategory.name}</span>    
                                </p> 
                                <p>
                                    <strong>Munkaállomás: </strong><span>{waste.piece.workstation.name}</span>    
                                </p>      
                                 
                                <p>
                                    <strong>Gyártási idő: </strong><span>{waste.piece.start}</span>    
                                </p> 
                                <p>
                                    <strong>Leírás: </strong><span>{waste.description}</span>    
                                </p>          
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductionInfo;