import { useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useAppDispatch } from "../../Hooks/TypedHooks";
interface customizedWindowState {
  from: string
};
const Login = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation();  
  const login = useCallback(() => {
    if(keycloak && !keycloak.authenticated) {      
      keycloak.login({
        'redirectUri': 'http://smartws-manager.animosolutions.hu/'
      });
    }    
  }, [keycloak]);  

  setTimeout(() => {
      login(); 
  }, 1000);

  if (keycloak?.authenticated) {      
    const state = location.state as customizedWindowState;     
    let redirectUrl: string =  "/";
    if(state) {
      redirectUrl = state.from;
    }
    else if(!state && location.pathname !== "/login") {
      redirectUrl = location.pathname;
    }
    return <Navigate to={{pathname: redirectUrl}}/>
  }
  return (
    <div>LoginPage</div>
  )
}
export default Login;