import { useGetWasteCategoriesQuery } from "../../Store/SwService";
import { Link } from "react-router-dom";
import WasteCategoryListItem from "./WasteCategoryListItem";

const WasteCategoryList = () => {
    const {data, isLoading} = useGetWasteCategoriesQuery(1);
    return (
        <div>
            <div className="d-flex justify-content-between mb-3">
                <h2 className="fs-4">Selejt kategóriák</h2> 
                <Link className="btn btn-primary" to="/waste-categories/create">
                    <i className="bi bi-plus-square me-2"></i>
                    <span>Új kategória</span>
                </Link>  
            </div>  
            <div className="row">
                {data && data.docs.map((category, index) => (
                    <div key={index} className="col-12 col-sm-3">
                        <WasteCategoryListItem name={category.name} id={category.id}></WasteCategoryListItem>
                    </div>
                ))}
            </div>          
        </div>
    );
};
export default WasteCategoryList;