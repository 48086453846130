import { useKeycloak } from "@react-keycloak/web";
import { Navigate, Outlet, useLocation } from "react-router-dom";
const ProtectedRoute = () => {  
  const location = useLocation();
  const {keycloak} = useKeycloak();
  if (!keycloak.authenticated) {
    return <Navigate to="login" state={{from: location.pathname}}  replace />;
  }  
  return <Outlet/>;
};
export default ProtectedRoute;