import { configureStore } from '@reduxjs/toolkit';
import userReducer from './UserService';
import { swApi } from './SwService';
const store = configureStore({
    reducer: {
      user: userReducer,
      [swApi.reducerPath]: swApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(swApi.middleware)   
      
});
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;

export function getStore() {
  return store;
};

export function getUserState() {
  return store.getState().user.value
}