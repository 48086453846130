import './App.scss';
import RouteList from './main/Routes/Routes';
import Sidebar from './main/Components/Common/Sidebar';
const App = () => {
  return (
    <div className="main d-flex flex-nowrap">
        <Sidebar></Sidebar>
        <div className="content py-3">
          <div className="container">
            <RouteList></RouteList>
          </div>          
        </div>
    </div>
  );
}
export default App;
