import { useCreateProductMutation } from "../../Store/SwService";
import { Link } from "react-router-dom";
import ProductForm from "./ProductForm";
import { initialProduct } from "../../Types/ProductTypes";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
const ProductCreate = () => {  
    const [createProduct, { isLoading, isSuccess, isError }] = useCreateProductMutation();
    const onSubmit = async (formData: any) => {
        const data = await createProduct({
            ...formData
        }); 
        
    };
    const navigate = useNavigate();
    useEffect(() => {

        if(isSuccess && !isError) {
            navigate("/products");
        }
        
    }, [isSuccess, isError]);
    return (
        <div>
            <div className="d-flex justify-content-between mb-3">
                <h2 className="fs-4">Új termék</h2>
                <Link className="btn btn-secondary" to="/products">
                    <i className="bi bi-arrow-left-square me-2"></i>
                    <span>Termékek</span>
                </Link>
            </div>  
            <ProductForm product={initialProduct} submit={onSubmit} ></ProductForm>
        </div>
    );
};
export default ProductCreate;