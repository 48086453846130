import { WorkstationProps } from "../../Types/WorkstationTypes";
import { Link } from "react-router-dom";
const WorkstationListItem = ({workstation}: WorkstationProps) => {
    return (
        <div className="card bg-light">
            <div className="card-body"> 
                <Link to={"/workstations/"+workstation.id} className="ext-decoration-none">
                    <h6 className="card-title text-decoration-none text-dark">{workstation.name}</h6>
                </Link>
            </div>
        </div>
    )
};

export default WorkstationListItem;