import { Product } from "./ProductTypes";
import { Workstation } from "./WorkstationTypes";

export type WasteCategory = {
    id: number,
    name: string
};

export const initialWasteCategory: WasteCategory = {
    id: 0,
    name: ""
};

export type Piece = {
    id: number,
    start: number,
    qualityCheck: string,
    product: Product,
    workstation: Workstation,
    waste?: Waste
};

export type Waste = {
    id: number,
    description: string,
    wasteCategory: WasteCategory,
    piece: Piece    
};