
import { useGetWorkstationQuery, useUpdateWorkstationMutation, useGetProductsQuery } from "../../Store/SwService";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { initialProduct } from "../../Types/ProductTypes";
const WorkstationUpdate = () => {  
    const { id } = useParams();
    const { data } = useGetWorkstationQuery(Number(id));
    const products  = useGetProductsQuery(1);
    const [updateWorkstation, { isLoading, isSuccess, isError }] = useUpdateWorkstationMutation();
    const onSubmit = (e: any) => {
        e.preventDefault();   
        updateWorkstation({            
            ...data,
            actualProduct: actualProduct
        });         
    };
    const [actualProduct, setActualProduct] = useState(initialProduct);
    const navigate = useNavigate();
    useEffect(() => {
        if(isSuccess && !isError) {
            navigate("/workstations");
        }        
    }, [isSuccess, isError]);
    
    useEffect(() => {
        if(data && data.actualProduct) {
            setActualProduct(data?.actualProduct);
        }        
    }, [data]);

    const handleChange = (id: number) => {
        const product = products.data?.docs.find(product => product.id === id);
        if(product) {
            setActualProduct(product);
        }        
    };
    
    
    return (
        <div>
            <div className="d-flex justify-content-between mb-3">
                <h2 className="fs-4">Munkaállomás módosítása: {data?.name}</h2>
                <Link className="btn btn-secondary" to="/workstations">
                    <i className="bi bi-arrow-left-square me-2"></i>
                    <span>Munkaállomások</span>
                </Link>
            </div>  
            <div>
                <form onSubmit={onSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Terméknév</label>
                        <select className="form-control" value={actualProduct.id} onChange={(e) => handleChange(Number(e.target.value))}>
                            <option value={0}>-</option>
                            {products.data && products.data.docs.map(product => <option key={product.id} value={product.id}>{product.name}</option>)}
                        </select>
                    </div>
                    <div>
                        <button type="submit" className="btn btn-primary">Mentés</button>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default WorkstationUpdate;