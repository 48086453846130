import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './BaseQueryWithReauth';
import { Product } from '../Types/ProductTypes';
import { PaginatedList } from '../Types/CommonTypes';
import { Workstation } from '../Types/WorkstationTypes';
import { WasteCategory, Piece, Waste } from '../Types/PieceTypes';
const queryLimit: Number = 1000; 
export const swApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: "https://smartws.animosolutions.hu",    
  }),
  tagTypes: ["PRODUCT", "WORKSTATION", "WASTECATEGORY", "PIECE", "WASTE"],
  endpoints: (build) => ({
    getProducts: build.query<PaginatedList<Product>, Number>({
      query: (page: Number = 1) => ({ url: `/product?page=${page}&limit=${queryLimit}`, method: 'get' }),
      transformResponse: (response: { data: PaginatedList<Product> }) => response.data,
      providesTags: ["PRODUCT"]
    }),
    getProduct: build.query<Product, Number>({
      query: (id: Number) => ({ url: `/product/${id}`, method: 'get' }),
      transformResponse: (response: { data: Product }) => response.data,
      providesTags: ["PRODUCT"]
    }),
    createProduct: build.mutation<Product, Partial<Product>>({
      query: (product: Product) => ({url: `/product`, method: 'post', data: product}),
      transformResponse: (response: { data: Product }) => response.data,
      invalidatesTags: ["PRODUCT"]
    }),
    updateProduct: build.mutation<Product, Partial<Product>>({
      query: (product: Product) => ({url: `/product`, method: 'put', data: product}),
      invalidatesTags: ["PRODUCT"] 
    }),
    deleteProduct:  build.mutation<Product, Partial<Product>>({
      query: (product: Product) => ({url: `/product/${product.id}`, method: 'delete'}),
      invalidatesTags: ["PRODUCT"]   
    }),
    getWorkstations: build.query<PaginatedList<Workstation>, Number>({
      query: (page: Number = 1) => ({ url: `/workstation`, method: 'get' }),
      transformResponse: (response: { data: PaginatedList<Workstation> }) => response.data,
      providesTags: ["WORKSTATION"]
    }),
    getWorkstation: build.query<Workstation, Number>({
      query: (id: Number) => ({ url: `/workstation/${id}`, method: 'get' }),
      transformResponse: (response: { data: Workstation }) => response.data,
      providesTags: ["WORKSTATION"]
    }),
    updateWorkstation: build.mutation<Workstation, Partial<Workstation>>({
      query: (workstation: Workstation) => ({url: `/workstation`, method: 'put', data: workstation}),
      invalidatesTags: ["WORKSTATION"] 
    }),
    deleteWorkstation:  build.mutation<Workstation, Partial<Workstation>>({
      query: (workstation: Workstation) => ({url: `/workstation/${workstation.id}`, method: 'delete'}),
      invalidatesTags: ["WORKSTATION"]   
    }),
    getWasteCategories: build.query<PaginatedList<WasteCategory>, Number>({
      query: (page: Number = 1) => ({ url: `/waste-category?page=${page}&limit=${queryLimit}`, method: 'get' }),
      transformResponse: (response: { data: PaginatedList<WasteCategory> }) => response.data,
      providesTags: ["WASTECATEGORY"]
    }),
    getWasteCategory: build.query<WasteCategory, Number>({
      query: (id: Number) => ({ url: `/waste-category/${id}`, method: 'get' }),
      transformResponse: (response: { data: WasteCategory }) => response.data,
      providesTags: ["WASTECATEGORY"]
    }),
    createWasteCategory: build.mutation<WasteCategory, Partial<WasteCategory>>({
      query: (wasteCategory: WasteCategory) => ({url: `/waste-category`, method: 'post', data: wasteCategory}),
      transformResponse: (response: { data: WasteCategory }) => response.data,
      invalidatesTags: ["WASTECATEGORY"]
    }),
    updateWasteCategory: build.mutation<WasteCategory, Partial<WasteCategory>>({
      query: (wasteCategory: WasteCategory) => ({url: `/waste-category`, method: 'put', data: wasteCategory}),
      invalidatesTags: ["WASTECATEGORY"] 
    }),
    deleteWasteCategory:  build.mutation<WasteCategory, Partial<WasteCategory>>({
      query: (wasteCategory: WasteCategory) => ({url: `/waste-category/${wasteCategory.id}`, method: 'delete'}),
      invalidatesTags: ["WASTECATEGORY"]   
    }),
    getPieces: build.query<PaginatedList<Piece>, {page: number; limit: number; products: Array<number>, workstations: Array<string>, filter: string}>({
      query: (args: any) => ({ url: `/piece/list`, method: 'post', data: args }),
      transformResponse: (response: { data: PaginatedList<Piece> }) => response.data,
      providesTags: ["PIECE"]
    }),
    getWaste: build.query<PaginatedList<Waste>, {page: number; limit: number; categoryIds: Array<number>, filter: string}>({
      query: (args: any) => ({ url: `/waste/list`, method: 'post', data: args }),
      transformResponse: (response: { data: PaginatedList<Waste> }) => response.data,
      providesTags: ["WASTE"]
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useGetWorkstationsQuery,
  useGetWorkstationQuery,
  useUpdateWorkstationMutation,
  useDeleteWorkstationMutation,
  useCreateWasteCategoryMutation,
  useDeleteWasteCategoryMutation,
  useUpdateWasteCategoryMutation,
  useGetWasteCategoriesQuery,
  useGetWasteCategoryQuery,
  useGetPiecesQuery,
  useGetWasteQuery
} = swApi;


