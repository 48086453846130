import { useGetWorkstationsQuery } from "../../Store/SwService";
import WorkstationListItem from "./WorkstationListItem";

const WorkstationList = () => {
    const {data, isLoading} = useGetWorkstationsQuery(1);
    console.log(data)
    return (
        <div>
            <div className="d-flex justify-content-between mb-3">
                <h2 className="fs-4">Munkaállomások</h2>            
            </div>  
            <div className="row">
                {data && data.docs.map((workstation, index) => (
                    <div key={index} className="col-12 col-sm-3">
                        <WorkstationListItem workstation={workstation}></WorkstationListItem>
                    </div>
                ))}
            </div>          
        </div>
    );
};
export default WorkstationList;