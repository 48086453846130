import React from 'react';
import ReactDOM from 'react-dom/client';
import { getStore } from './main/Store/Store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import AuthHelper from './main/Helpers/AuthHelper';
import { Provider } from 'react-redux';
import { User } from './main/Types/UserTypes';
import { login  }  from "./main/Store/UserService";
const keycloak = AuthHelper.init();
const store = getStore();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const onKeycloakEvent = (token: any) => {
    const user: User = AuthHelper.parseToken(token.token);    
    store.dispatch(login(user));
    AuthHelper.saveToken(token.token);    
};
root.render(  
    <ReactKeycloakProvider authClient={keycloak} onTokens={onKeycloakEvent} initOptions={{checkLoginIframe: false}}>
      <React.StrictMode>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>          
        </BrowserRouter>
      </React.StrictMode>
    </ReactKeycloakProvider>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
