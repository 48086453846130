import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import ProductList from '../Components/Products/ProductList';
import Login from '../Components/Auth/LoginComponent';
import DashboardMain from '../Components/Dashboard/DashboardMain';
import ProductShow from '../Components/Products/ProductShow';
import ProductCreate from '../Components/Products/ProductCreate';
import ProductUpdate from '../Components/Products/ProductUpdate';
import WorkstationList from '../Components/Workstation/WorkstationList';
import WorkstationShow from '../Components/Workstation/WorkstationShow';
import WorkstationUpdate from '../Components/Workstation/WorkstationUpdate';
import WasteCategoryList from '../Components/WasteCategory/WasteCategoryList';
import WasteCategoryCreate from '../Components/WasteCategory/WasteCategoryCreate';
import WasteCategoryShow from '../Components/WasteCategory/WasteCategoryShow';
import WasteCategoryUpdate from '../Components/WasteCategory/WasteCategoryUpdate';
import ProductionInfo from '../Components/Production/ProductionInfo';
const RouteList = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute/>}>
                <Route path="/" element={<DashboardMain />} />
                <Route path="products/:id" element={<ProductShow />} />
                <Route path="products/create" element={<ProductCreate />} /> 
                <Route path="products/:id/update" element={<ProductUpdate />} /> 
                <Route path="products" element={<ProductList />} />    
                <Route path="/" element={<DashboardMain />} />
                <Route path="workstations/:id" element={<WorkstationShow />} />
                <Route path="workstations/:id/update" element={<WorkstationUpdate />} /> 
                <Route path="workstations" element={<WorkstationList />} /> 
                <Route path="waste-categories" element={<WasteCategoryList />} /> 
                <Route path="waste-categories/create" element={<WasteCategoryCreate />} /> 
                <Route path="waste-categories/:id" element={<WasteCategoryShow />} />
                <Route path="waste-categories/:id/update" element={<WasteCategoryUpdate />} />
                <Route path="production-info" element={<ProductionInfo />} />
            </Route> 
            <Route path="login" element={<Login />}></Route>
        </Routes>       
    );
};
export default RouteList;