import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import axios from 'axios'
import type { AxiosRequestConfig, AxiosError } from 'axios'
import { getStore } from './Store';
import AuthHelper from '../Helpers/AuthHelper';

const axiosApiInstance = axios.create();
axiosApiInstance.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {  
      const keycloak = AuthHelper.init();
      await keycloak.updateToken(60)
      originalRequest._retry = true;
      axiosApiInstance.defaults.headers.common['Authorization'] = 'Bearer ' + keycloak.token;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
});
export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {      
      const store = getStore();
      axiosApiInstance.defaults.headers.common['Authorization'] = 'Bearer ' + store.getState().user.value.token;
      const result = await axiosApiInstance({ url: baseUrl + url, method, data, params })
      return { data: result.data }
    } catch (axiosError) {
      let err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }
