
import { useGetWasteCategoryQuery, useUpdateWasteCategoryMutation } from "../../Store/SwService";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
const WasteCategoryUpdate = () => {  
    const { id } = useParams();
    const { data } = useGetWasteCategoryQuery(Number(id));
    const [categoryName, setCategoryName] = useState<string>();

    const [updateWorkstation, { isLoading, isSuccess, isError }] = useUpdateWasteCategoryMutation();
    const onSubmit = (e: any) => {
        e.preventDefault();   
        updateWorkstation({            
            id: data?.id,
            name: categoryName
        });         
    };
    const navigate = useNavigate();
    useEffect(() => {
        if(isSuccess && !isError) {
            navigate("/waste-categories");
        }        
    }, [isSuccess, isError]);
    
    useEffect(() => {
        setCategoryName(data?.name);
    }, [data]);

    const handleChange = (name: string) => {
        setCategoryName(name);
    };
    
    
    return (
        <div>
            <div className="d-flex justify-content-between mb-3">
                <h2 className="fs-4">Selejt kategória módosítása: {data?.name}</h2>
                <Link className="btn btn-secondary" to="/waste-categories">
                    <i className="bi bi-arrow-left-square me-2"></i>
                    <span>Munkaállomások</span>
                </Link>
            </div>  
            <div>
                <form onSubmit={onSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Kategórianév</label>
                        <input type="text" className="form-control" value={categoryName} onChange={(e) => handleChange(e.target.value)}/>
                    </div>
                    <div>
                        <button type="submit" className="btn btn-primary">Mentés</button>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default WasteCategoryUpdate;