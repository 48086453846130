import { Link } from "react-router-dom";
import { useGetProductsQuery } from "../../Store/SwService";
import ProductListItem from "./ProductListItem";

const ProductList = () => {
    const {data, isLoading} = useGetProductsQuery(1);
    console.log(data)
    return (
        <div>
            <div className="d-flex justify-content-between mb-3">
                <h2 className="fs-4">Termékek</h2>
                <Link className="btn btn-primary" to="/products/create">
                    <i className="bi bi-plus-square me-2"></i>
                    <span>Új termék</span>
                </Link>
            </div>  
            <div className="row">
                {data && data.docs.map((product, index) => (
                    <div key={index} className="col-12 col-sm-3">
                        <ProductListItem product={product}></ProductListItem>
                    </div>
                ))}
            </div>          
        </div>
    );
};
export default ProductList;